<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t('message.update_patient_history_type') }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)">{{ $t('message.save_and_close') }}</el-button>
              <el-button @click="close()">{{  $t('message.close') }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row" v-loading="loadingData">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <el-form ref="form" :model="form" :rules="rules" class="aticler_m" size="small">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item :label="$t('message.branch')" prop="name">
                  <el-input v-model="form.name" autocomplete="off"/>
                </el-form-item>
              </el-col>
              <el-col :sm="6">
                <el-form-item :label="$t(`message.work_time`)">
                  <el-time-picker
                    v-model="form.timerange"
                    range-separator="-"
                    size="large"
                    is-range
                  />
                </el-form-item>
              </el-col>
              <el-col :sm="12">
                <el-form-item :label="$t(`message.active`)">
                  <div class="el-input el-input--small ser_chek">
                    <el-checkbox
                      v-model="form.active"
                      :label="$t('message.active')"
                      border
                      size="medium"
                    />
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex';
import form from '@/utils/mixins/form';
import drawerChild from '@/utils/mixins/drawer-child';
export default {
  mixins:[form, drawerChild],
  props: ['selected'],
  data(){
    return {
      loadingData: false
    }
  },
  computed: {
    ...mapGetters({
      rules: 'patientHistoryTypes/rules',
      model: 'patientHistoryTypes/model',
      columns: 'patientHistoryTypes/columns',
    })
  },
  methods:{
    ...mapActions({
      save: 'patientHistoryTypes/update',
      editModel: 'patientHistoryTypes/show',
      empty: 'patientHistoryTypes/empty',
    }),
    afterOpened(){
      if (this.selected && !this.loadingData) {
        this.loadingData = true;
        this.editModel(this.selected.id)
          .then(async (res) => {
            this.loadingData = false;
            this.form = JSON.parse( JSON.stringify(this.model) );

            const today = new Date().toISOString().split("T")[0];
            this.form.timerange = this.form.timerange.map(time => new Date(`${today}T${time}`));
          })
          .catch(err => {
            this.loadingData = false;
            this.$notify({
              title: "Ошибка",
              type: "error",
              offset: 130,
              message: err.error.message,
            })
          });
      }

    },
    afterClosed(){
      this.empty()
    },
    loadModel(){
      this.form = JSON.parse( JSON.stringify( this.model ));
    },
    submit(close = true){
      this.$refs['form'].validate((valid) => {
        if (valid){
          this.loadingButton = true;
          this.form.timerange = this.form.timerange.map(time => {
            return time.toLocaleTimeString("ru-RU", { hour12: false });
          })

          this.save(this.form)
            .then(res => {
              this.loadingButton = false;
              this.$notify({
                title: 'Успешно',
                type: "success",
                offset: 130,
                message: res.message
              });
              this.parent().listChanged()
              if (close == true) {
                this.close();
              }
            })
            .catch(err => {
              this.loadingButton = false;
              this.$notify({
                title: 'Ошибка',
                type: "error",
                offset: 130,
                message: err.error.message
              });
            });
        }
      });
    }
  }
}

</script>
